<template>
<div class="container-fluid">
    <heading></heading>
    <div class="container">          
        <div class="row ct-rt" v-if="isCT">
            <div class="col-12">
                <p>
                    Randy and Susan Woodson's commitment to our university — and its people — has inspired and forever changed so many of us. Celebrate this impact and the 
                    ongoing transformation of NC State with a gift to one of their top priorities, or the area that means the most to you.               
                </p>
            </div>
        </div>        
        <div class="row">            
            <div class="col">                
                <div class="container-cart mb-3 p-1 border-bottom" :class="{'ct': isCT}">
                    <div class="row align-items-center text-right">
                        <div class="col">
                            <img class="align-baseline" src="@/assets/images/gift.png" alt="My cart">
                            ${{formatAmount(cart.amount)}} to {{cart.funds.length + cart.other}} fund<span v-if="cart.funds.length + cart.other != 1">s</span>                        
                            <button type="button" v-if="cartEditEnabled" v-on:click="setEditing(!editing)" data-toggle="collapse" data-target=".cartDetail" class="btn btn-sm btn-red ml-2">
                                <span v-if="editing"><i class="fas fa-check"></i> Done</span>
                                <span v-else><i class="fas fa-pencil"></i> Edit cart</span>    
                            </button>      
                        </div>
                    </div>                
                    <div class="cartDetail collapse">                        
                        <ul class="list-group list-group-flush text-right">
                            <li class="list-group-item" :key="i" v-for="(a, i) in cart.funds">
                                <span class="mr-2">{{a.ENCOMPASS_NAME}}</span>
                                <button type="button" v-on:click="removeFromCart(a)" class="float-right btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove</button>    
                            </li>
                            <li class="list-group-item" v-if="cart.other">
                                <span class="mr-2">Other fund<span v-if="cart.other && cart.otherDesc">: {{cart.otherDesc}}</span></span>
                                <button type="button" v-on:click="removeOther()" class="float-right btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove</button>    
                            </li>
                        </ul>  
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2" >
            <div class="col-12" v-if="siteStatus.status != 'normal'">
                <div class="alert site-alert" :class="{'alert-warning': siteStatus.status == 'degraded', 'alert-danger': siteStatus.status == 'down'}">
                    <i class="fad" :class="{'fa-exclamation-triangle': siteStatus.status == 'degraded', 'fa-exclamation-circle': siteStatus.status == 'down'}"></i>                     
                    {{siteStatus.message}}
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col">
                        <button type="button" v-on:click="setAmount(50)" :class="{'active': activeBtn == 50}" class="btn btn-amt"><sup>$</sup>50</button>                
                    </div>
                    <div class="col">
                        <button type="button" v-on:click="setAmount(100)" :class="{'active': activeBtn == 100}" class="btn btn-amt"><sup>$</sup>100</button>                
                    </div>
                    <div class="col">
                        <button type="button" v-on:click="setAmount(500)" :class="{'active': activeBtn == 500}" class="btn btn-amt"><sup>$</sup>500</button>                
                    </div>
                    <div class="col">
                        <button type="button" v-on:click="setAmount('other')" :class="{'active': activeBtn == 'other'}" class="btn btn-amt">Other</button>                
                    </div>
                </div>
                
                <div id="otherAmount" class="row-other row justify-content-center">
                    <div class="col-lg-10 col-xl-8 col-12">
                        <div class="row">
                            <div class="col-lg-6 col-12">                                   
                                <span class="h3"><label for="otherAmount">My Gift Amount:</label></span>
                            </div>
                            <div class="col-lg-6 col-12">
                                <div class="input-group input-group-lg">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">$</span>
                                    </div>
                                    <currency-input v-model="cart.amount" :options="{ currency: 'USD', currencyDisplay: 'hidden', precision: 2 }" id="otherAmount" title="Other gift amount" v-on:change="testAmount()"  placeholder="Other amount" class="form-control" />
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>                    
                <!--
                <div class="row border-top pt-2">
                    <div class="col">
                        <div class="custom-control-inline custom-radio ml-3">
                            <input type="radio" v-model="cart.giftType" value="now" id="now" name="giftType" class="custom-control-input" />
                            <label class="custom-control-label" for="now"><h4>I'd like to make a one-time gift</h4></label>
                        </div>
                        <div class="custom-control-inline custom-radio ml-3">
                            <input type="radio" v-model="cart.giftType"  value="scheduled"  id="scheduled " name="giftType" class="custom-control-input" />
                            <label class="custom-control-label" for="scheduled "><h4>I'd like to make a recurring gift</h4></label>
                        </div>
                        <div class="custom-control-inline custom-radio ml-3">
                            <input type="radio" v-model="cart.giftType"  value="perpetual"  id="perpetual" name="giftType" class="custom-control-input" />
                            <label class="custom-control-label" for="perpetual"><h4>I'd like to make a perpetual gift</h4></label>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
        <transition name="default" mode="out-in">
            
            <div class="row" v-if="step == '1'" key="1">
                <div class="col-12">
                    <div class="row grey pt-3 mb-2">
                        <div class="col">
                            <div class="panel pb-2">
                                <h2>University Priorities</h2>
                                <div class="row ml-1 mr-1">                                    
                                    <div class="col-12">
                                        <div class="row justify-content-lg-center">
                                            <div class="areas col-lg-6 col-12 mb-lg-2">                                                
                                                <button type="button" v-on:click.self="featuredToggle(0)" class="btn btn-block">                                                
                                                    <h3 class="custom-control custom-control-lg custom-checkbox">                           
                                                        <input type="checkbox" :checked="featured[0].inCart" v-on:change.self="featuredToggle(0)" class="custom-control-input" id="fund0Check">
                                                        <label class="text-right text-white mt-2 custom-control-label" for="fund0Check">{{featured[0].description}}
                                                            <i v-if="featured[0].ALLOCATION_DESC > ''" class="fas fa-info-circle" v-tooltipred:bottom="featured[0].ALLOCATION_DESC"></i>
                                                        </label>
                                                    </h3>                                        
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="areas col-lg-6 col-12 mt-2 mt-lg-0">
                                                <button type="button" v-on:click.self="featuredToggle(1)" class="btn btn-block">                                                
                                                    <h3 class="custom-control custom-control-lg custom-checkbox">                           
                                                        <input type="checkbox" :checked="featured[1].inCart" v-on:change.self="featuredToggle(1)" class="custom-control-input" id="fund1Check">
                                                        <label class="text-right text-white mt-2 custom-control-label" for="fund1Check">{{featured[1].description}}
                                                            <i v-if="featured[1].ALLOCATION_DESC > ''" class="fas fa-info-circle" v-tooltipred:bottom="featured[1].ALLOCATION_DESC"></i>
                                                        </label>
                                                    </h3>                                        
                                                </button>
                                            </div>
                                            <div class="areas col-lg-6 col-12 mt-2 mt-lg-0">
                                                <button type="button" v-on:click.self="featuredToggle(2)" class="btn btn-block">                                                
                                                    <h3 class="custom-control custom-control-lg custom-checkbox">                           
                                                        <input type="checkbox" :checked="featured[2].inCart" v-on:change.self="featuredToggle(2)" class="custom-control-input" id="fund2Check">
                                                        <label class="text-right text-white mt-2 custom-control-label" for="fund2Check">{{featured[2].description}}
                                                            <i v-if="featured[2].ALLOCATION_DESC > ''" class="fas fa-info-circle" v-tooltipred:bottom="featured[2].ALLOCATION_DESC"></i>
                                                        </label>
                                                    </h3>                                        
                                                </button>
                                            </div>                                       
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                        </div>            
                    </div>                
                    <div class="row pt-3 mb-2 grey">
                        <div class="col">
                            <div class="panel">
                                <div class="row align-items-center">
                                    <div class="col-sm-7 col-12">
                                         <h2>
                                            <span v-if="areasLoading"><i class="fas fa-circle-notch fa-spin"></i> Loading...</span>
                                            <span v-else>Area of Support</span>                                            
                                        </h2> 
                                    </div>
                                    <div class="col-sm-5 col-12">                                                                              
                                        <button v-if="subArea.isSubArea && subArea.area.PORTAL_IND == 'N'" type="button" v-on:click="loadAliasFunds(false)" class="btn btn-red float-right mr-2">{{subArea.area.TEMPLATE_PARENT_TITLE}} <i class="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>                      
                                <div class="row ml-1 mr-1">                                    
                                    <div class="areas col-lg-6 col-xl-4 col-12" :key="k" v-for="(a, k) in portalAreas">                                        
                                        <button type="button" v-on:click="loadAliasFunds(a)" class="pr-1 pl-1 btn btn-area">{{a.TEMPLATE_TITLE}}</button>
                                    </div>
                                    <span id="area-announce" aria-live="assertive"></span>                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <div v-else-if="step == '2'" key="2">
                <div class="row pt-3 mb-3 grey">
                    <div class="col">
                        <div class="panel">
                            <div class="row align-items-center">
                                <div class="col-sm-9 col-12">
                                    <h2>{{area.TEMPLATE_TITLE}}</h2>
                                </div>
                                <div class="col-sm-3 col-12">
                                <!-- Back if from main page, something else if insertion point -->
                                    <button v-if="subArea.isSubArea" type="button" v-on:click="showAllAreas()" class="btn-back btn btn-red float-right m-0 mr-sm-2"><i class="fas fa-arrow-left"></i> All areas of support</button>                                     
                                    <button v-if="!subArea.isSubArea" type="button" v-on:click="showAllAreas()" class="btn-back btn btn-red float-right m-0 mr-sm-2"><i class="fas fa-arrow-left"></i> Back to all areas</button>                                                                         
                                </div>
                            </div>
                            <div class="row">
                                <div class="col area-funds">                        
                                <transition name="featuredAllocations" mode="out-in">
                                    <div>                                                                                        
                                        <h3 class="ml-3 mt-2" v-if="aliasLoading">
                                            <i class="fas fa-circle-notch fa-spin"></i> Loading...
                                        </h3>
                                        <h3 class="m-2" v-if="!featuredAllocations.CATEGORIES.length">This area contains no funds</h3>
                                        <ul v-else id="categories" class="list-group list-group-flush">
                                            <li class="list-group-item" :key="k" v-for="(n, k) in featuredAllocations.CATEGORIES">
                                                <h3 v-if="featuredAllocations.CATEGORIES.length > 1" class="category-title mb-0 p-2 ml-n2 mr-n2" data-toggle="collapse" :data-target="'#collapse_'+n.CATEGORY_ID" v-on:click="n.expand = !n.expand">
                                                    <i class="ml-2 mr-2 fas fa-chevron-down" :class="{'collapsed': !n.expand}"></i>                                                                                  
                                                    <span>{{n.CATEGORY_NAME}}</span>
                                                </h3>
                                                <ul class="list-group list-group-flush collapse show" :id="'collapse_'+n.CATEGORY_ID">                                                    
                                                    <li class="list-group-item clearfix" :key="i" v-for="(a, i) in n.ALLOCATIONS">                                                                                                        
                                                        <div class="float-left fund-name pt-1">{{a.ENCOMPASS_NAME}} <i v-if="a.ALLOCATION_DESC > ''" class="fas fa-info-circle" v-tooltip:bottom="a.ALLOCATION_DESC"></i></div>
                                                        <div class="float-right">
                                                            <button type="button" v-if="!a.inCart" v-on:click="addToCart(a)" class="btn btn-sm btn-red"><i class="fas fa-plus"></i> Add to cart</button>                                                
                                                            <button type="button" v-if="a.inCart" v-on:click="removeFromCart(a)" class="btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove from cart</button>    
                                                        </div>
                                                    </li>
                                                </ul>                                  
                                            </li>                                                 
                                        </ul>                            
                                    </div>
                                </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                

                <div class="row pt-3 mb-3 grey" v-if="!isCT">
                    <div class="col">
                        <div class="panel">
                            <div class="row align-items-center">
                                <div class="col-9 col-xs-12">
                                    <h2>{{uw.TEMPLATE_TITLE}}</h2>
                                </div>                                
                            </div>
                            <div class="row">
                                <div class="col area-funds">                        
                                <transition name="UWfeaturedAllocations" mode="out-in">
                                    <div>                                                
                                        <h3 class="ml-3 mt-2" v-if="aliasLoading">
                                            <i class="fas fa-circle-notch fa-spin"></i> Loading...
                                        </h3>
                                        <h3 class="m-2" v-if="!uw.CATEGORIES.length">This area contains no funds</h3>
                                        <ul v-else id="categories" class="list-group list-group-flush">
                                            <li class="list-group-item" :key="k" v-for="(u, k) in uw.CATEGORIES">
                                                <ul class="list-group list-group-flush collapse show" :id="'collapse_'+u.CATEGORY_ID">
                                                    <li class="list-group-item clearfix" :key="kk" v-for="(b, kk) in u.ALLOCATIONS">
                                                        <div class="float-left fund-name pt-1 has-tooltip">{{b.ENCOMPASS_NAME}} <i v-if="b.ALLOCATION_DESC > ''" class="fas fa-info-circle" v-tooltip:bottom="b.ALLOCATION_DESC"></i></div>
                                                        <div class="float-right">
                                                            <button type="button" v-if="!b.inCart" v-on:click="addToCart(b)" class="btn btn-sm btn-red"><i class="fas fa-plus"></i> Add to cart</button>                                                
                                                            <button type="button" v-if="b.inCart" v-on:click="removeFromCart(b)" class="btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove from cart</button>    
                                                        </div>
                                                    </li>
                                                </ul>                                  
                                            </li>                                                 
                                        </ul>                            
                                    </div>
                                </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </transition>

        <div class="row mb-4 grey pt-3">
            <div class="col">
                <div class="panel">
                    <h2>Search All Funds</h2>
                    <div class="row">
                        <div class="col p-1 pl-sm-2 pr-sm-2">
                            <allocation-select :loading="loading" :allocations="otherAllocations"></allocation-select>
                            <div class="p-2 border-top">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" :checked="otherVisible" v-on:click="toggleOther()" class="custom-control-input" id="otherFundCheck">
                                    <label class="custom-control-label pt-1" for="otherFundCheck">I would like to give to a fund not listed here</label>
                                </div>
                            </div>
                            <div v-if="otherVisible" class="form-row pl-2 pr-2">
                                <div class="col-12 mb-2">
                                    <span class="align-middle"><i class="fas fa-bell-on"></i> In the box below, enter a description of how your gift should be used.</span>
                                </div>
                                <div class="col input-group">
                                    <input type="text" id="otherDesc" v-model="cart.otherDesc" placeholder="How should your gift be used?" class="form-control"> 
                                    <label for="otherDesc"></label> 
                                    <div class="input-group-append">
                                        <button v-if="!cart.other" :disabled="cart.otherDesc.trim() == ''" type="button" v-on:click="addToCart('other')" class="btn btn-red"><i class="fas fa-plus"></i> Add to cart</button>                                                
                                        <button v-else type="button" v-on:click="removeOther()" class="btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove from cart</button>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
         <div class="row">
            <div class="col">
                <div class="p-1 mb-2 border-bottom">
                    <div class="row align-items-center text-right">
                        <div class="col">
                            <img class="align-baseline" src="@/assets/images/gift.png" alt="My cart">
                            ${{formatAmount(cart.amount)}} to {{cart.funds.length + cart.other}} fund<span v-if="cart.funds.length + cart.other != 1">s</span>                        
                            <button type="button" v-if="cartEditEnabled" v-on:click="setEditing(!editing)" data-toggle="collapse" data-target=".cartDetail" class="btn btn-sm btn-red ml-2">
                                <span v-if="editing"><i class="fas fa-check"></i> Done</span>
                                <span v-else><i class="fas fa-pencil"></i> Edit cart</span>    
                            </button>      
                        </div>
                    </div>                
                    <div class="cartDetail collapse">                        
                        <ul class="list-group list-group-flush text-right">
                            <li class="list-group-item" :key="i" v-for="(a, i) in cart.funds">
                                <div class="row pr-3">
                                    <div class="col-9 col-sm-11">
                                        {{a.ENCOMPASS_NAME}}
                                    </div>
                                    <div class="col-3 text-left text-sm-right p-0 col-sm-1">
                                        <button type="button" v-on:click="removeFromCart(a)" class="btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove</button>    
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item" v-if="cart.other">
                                <div class="row pr-3">
                                    <div class="col-9 col-sm-11">
                                        Other fund<span v-if="cart.other && cart.otherDesc">: {{cart.otherDesc}}</span>
                                    </div>
                                    <div class="col-3 text-left text-sm-right p-0 col-sm-1">
                                        <button type="button" v-on:click="removeOther()" class="btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove</button>    
                                    </div>
                                </div>
                            </li>
                        </ul>  
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-sm-5 mb-3 mt-2">
            <div class="col text-center">
                <button type="button" :disabled="!checkoutEnabled || siteStatus.status == 'down'" v-on:click="checkout()" class="btn btn-continue">
                    <span>Continue</span> <i class="fas fa-chevron-right"></i>
                </button>     
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>
<script>
import allocationSelect from './allocation.select.vue';
import heading from './head.vue';
import foot from './foot.vue';
import currencyInput from './give/currencyInput.vue';
export default {   
    components: {
        allocationSelect: allocationSelect,
        heading: heading,
        foot: foot,
        currencyInput: currencyInput
    },
    data() {
        return {
            isCT: false,
            loading: false,
            aliasLoading: false,
            areasLoading: false,
            editing: false,
            siteStatus: {status: 'normal', message: ''},
            subArea: {'isSubArea': false, 'area': {}},
            step: '1',
            areas: [],
            portalAreas: [],
            featured: [],                
            area: {},
            uw: {},
            uwAlias: 'otherareas/',
            featuredAllocations: [],
            otherAllocations: [],            
            cart: {other: false, otherDesc: '', amount: 0, giftType: 'now', funds: []},
            otherVisible: false,
            activeBtn: false,
            sites: [
                {allocation_school: 'GU', campaign_id: '11690', page_id: '7348'}
            ],
            dataLayer: window.dataLayer
        }
    },
    mounted() {
        if (this.$route.params.area == 'celebratingtransformation') {
            this.isCT = true;
        }
        this.loadData();
    },
    computed: {
        checkoutEnabled: function() {
            var out = false;
          //  if ((this.cart.funds.length > 0 || this.cart.other === true) && this.cart.amount > 0.00) {
            if (this.cart.funds.length > 0 || this.cart.other === true) {
                out = true;
            }
            return out;
        },
        cartEditEnabled: function() {
            var out = false;
            if ((this.cart.funds.length > 0 || this.cart.other === true)) {
                out = true;
            }
            return out;
        }
    },    
    methods: {
        loadData: function() {    
            if (this.$cookies.isKey('ncsu-og-portal')) {
                let c = this.$cookies.get('ncsu-og-portal');                
                this.cart = c.cart;                
                //Set amount button                
                switch (this.cart.amount) {
                    case '100.00': 
                        this.activeBtn = 100;
                    break;
                    case '250.00': 
                        this.activeBtn = 250;
                    break;
                    case '500.00': 
                        this.activeBtn = 500;
                    break;
                    case '1000.00': 
                        this.activeBtn = 1000;
                    break;
                    case '0.00': 
                        this.activeBtn = false;
                    break;
                    default: 
                        this.activeBtn = 'other';
                    break;
                }                                
            }      
            this.loading = true;
            this.areasLoading = true;
            const promiseAllAllocations = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/json/all-allocations.json'); 
            const promisePortalAliases = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/json/portal-allocations.json');
            const promiseStatus = this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/json/site-status.json');
            Promise.all([promiseAllAllocations, promisePortalAliases, promiseStatus])
            .then(response => {	                   
                this.siteStatus.status = response[2].data[0].SITE_STATUS;                             
                this.siteStatus.message = response[2].data[0].SITE_MESSAGE;
                if (response[0].data.error) {
                    this.$parent.setAlert(true, response[0].data.error, 'alert-danger');
                    this.loading = false;
                    return;
                }
                this.loading = false;                               	
                this.otherAllocations = response[0].data;
            
                //Add
                for (var i = 0; i < response[1].data.length; i++) {
                    if (response[1].data[i].PORTAL_IND == 'Y') {
                        this.portalAreas.push(response[1].data[i]);
                    }
                }
                this.areas = response[1].data;
                this.areasLoading = false;
                this.uw = this.areas.filter(u => {
                    return u.CAMPAIGN_ALIAS == this.uwAlias;
                })[0]; //University-wide allocations   
                let fund1 = this.uw.CATEGORIES[0].ALLOCATIONS.find(a => a.ALLOCATION_CODE == '149435');
                fund1.description = 'The Area of Greatest Need';
                this.featured.push(fund1);

                let fund2 = this.uw.CATEGORIES[0].ALLOCATIONS.find(a => a.ALLOCATION_CODE == '147945');
                fund2.description = 'Chancellor\'s Transformational Endowment';
                this.featured.push(fund2);

                let fund3 = this.uw.CATEGORIES[0].ALLOCATIONS.find(a => a.ALLOCATION_CODE == '147328');
                fund3.description = 'Extraordinary Opportunity Scholarship';
                this.featured.push(fund3);

                for (var i = 0; i < this.otherAllocations.length; i++) {                                               
                    if (this.cart.funds.some(a => a.ALLOCATION_CODE == this.otherAllocations[i].ALLOCATION_CODE)) {
                        this.$set(this.otherAllocations[i], 'inCart', true);                            
                    } else {                                        
                        this.$set(this.otherAllocations[i], 'inCart', false);
                    }                    
                }                
                for (var i = 0; i < this.uw.CATEGORIES[0].ALLOCATIONS.length; i++) {                                               
                    if (this.cart.funds.some(a => a.ALLOCATION_CODE == this.uw.CATEGORIES[0].ALLOCATIONS[i].ALLOCATION_CODE)) {
                        this.$set(this.uw.CATEGORIES[0].ALLOCATIONS[i], 'inCart', true);                            
                    } else {                                        
                        this.$set(this.uw.CATEGORIES[0].ALLOCATIONS[i], 'inCart', false);
                    }                    
                }
            
                if (this.cart.other === true && this.cart.otherDesc.trim() > '') {
                    this.otherVisible = true;
                    this.otherDesc = this.cart.otherDesc;
                }

                //Set allocation school
                if (this.$route.params.area) {
                    this.$route.params.area = this.$route.params.area.toLowerCase();                    
                    this.area = this.areas.filter(a => {
                        return a.CAMPAIGN_ALIAS == this.$route.params.area+'/';
                    })[0];                    
                    if (this.area) {  
                        this.subArea = {'isSubArea': true, 'area': this.area};                           
                        this.loadAliasFunds(this.area);
                    } else {
                        this.$router.replace('/');
                    }
                }
            });
        },
        formatAmount(value) {
            let val = (value/1).toFixed(2);
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        toGAItem: function(fund) {
            var cartAmount = this.cart.amount.toString().replace(/\,/g,'');
            cartAmount = parseFloat(cartAmount);    
            var numFunds = this.cart.funds.length; //How many funds have we donated to?
            if (this.cart.other) {
                numFunds = numFunds + 1;
            }            
            var sub = (cartAmount / numFunds).toFixed(2);                   
    
            var item = {                              
                item_name: fund.ALLOCATION_NAME,
                price: sub,                
                item_id: fund.ENCOMPASS_ID,                
                affiliation: this.getAppeal(),
                item_list: fund.SCHOOL_NAME,            
                item_category: fund.CATEGORY_NAME,            
                quantity: 1,
                currency: "USD"
            }                            
            return item;
        },
        featuredToggle: function(fund) {
            if (this.featured[fund].inCart) {
                this.removeFromCart(this.featured[fund]);
            } else {
                this.addToCart(this.featured[fund]);
            }
        },
        getAppeal: function() {
            var out = '';
            if (this.$route.query.appealcode) {
                out = this.$route.query.appealcode;
            } else {
                if (this.$route.params.area) { //If the donor arrives on this page from a passed-in area URL, add that area's appeal code                                                        
                    out = this.subArea.area.APPEAL_CODE;                    
                }
            }
            return out;
        },
        addToCart: function(a) {
            if (a == 'other') {
                this.cart.other = true;                
            } else {
                a.inCart = true;
                this.cart.funds.unshift(a);  
            }
            var cookieObj = {cart: this.cart};  
            var cartAmount = this.cart.amount.toString().replace(/\,/g,'');
            cartAmount = parseFloat(cartAmount);       
            window.dataLayer.push({
                'event': "add_to_cart",
                'ecommerce': {
                    'currency': "USD",
                    'value': cartAmount,	
                    'affiliation': this.getAppeal(),						
                    'items': [this.toGAItem(a)]
                }
            });                           
            this.$cookies.set('ncsu-og-portal', cookieObj);          
        },
        fundInCart: function(a) {
            var out = false;
            if (this.cart.funds.find(f => f.ENCOMPASS_ID == a.ENCOMPASS_ID)) {
                out = true;
            }
            return out;
        },
        removeFromCart: function(a) {
            a.inCart = false;     
            var cartAmount = this.cart.amount.toString().replace(/\,/g,'');
            cartAmount = parseFloat(cartAmount);  
            window.dataLayer.push({
                'event': "remove_from_cart",
                'ecommerce': {
                    'currency': "USD",
                    'value': cartAmount,	
                    'affiliation': this.getAppeal(),						
                    'items': [this.toGAItem(a)]				
                }
            });
            this.cart.funds = this.cart.funds.filter(c => {
                return a.ENCOMPASS_ID != c.ENCOMPASS_ID;
            });  
            this.otherAllocations.filter(o => {
                return o.ENCOMPASS_ID == a.ENCOMPASS_ID;
            })[0].inCart = false;
            var cookieObj = {cart: this.cart};
            this.$cookies.set('ncsu-og-portal', cookieObj);          
            //If this empties the cart, collapse it
            if (this.cart.funds.length + this.cart.other === 0) {
                $('.cartDetail').collapse('hide');
                this.setEditing(false);                
            }
        },
        addOther: function() {
            if (this.cart.other) {
                return;
            }
            this.cart.other = true;            
            var cookieObj = {cart: this.cart};
            var a = {ALLOCATION_NAME: 'Other fund', ENCOMPASS_ID: '9237'};
            var cartAmount = this.cart.amount.toString().replace(/\,/g,'');
            cartAmount = parseFloat(cartAmount);  
            window.dataLayer.push({
                'event': "add_to_cart",
                'ecommerce': {                     
                    'currency': "USD",
                    'value': cartAmount,	
                    'affiliation': this.getAppeal(),				
                    'items': [this.toGAItem(a)]                
                }                
            });            
            this.$cookies.set('ncsu-og-portal', cookieObj);   
        },        
        toggleOther: function() {
            if (this.otherVisible) {
                this.removeOther();
            }
            this.otherVisible = !this.otherVisible;
        },
        removeOther: function() {
            var a = {ALLOCATION_NAME: 'Other fund', ENCOMPASS_ID: '9237'};
            var cartAmount = this.cart.amount.toString().replace(/\,/g,'');
            cartAmount = parseFloat(cartAmount);            
            window.dataLayer.push({
                'event': "remove_from_cart",                
                'ecommerce': {
                    'currency': "USD",
                    'value': cartAmount,	
                    'affiliation': this.getAppeal(),				
                    'items': [this.toGAItem(a)]
                }	
            });           
            this.cart.other = false;
            this.cart.otherDesc = '';
            if (this.cart.funds.length + this.cart.other === 0) {
                $('.cartDetail').collapse('hide');
                this.setEditing(false);                
            }
            var cookieObj = {cart: this.cart};            
            this.$cookies.set('ncsu-og-portal', cookieObj);   
        },
        showAllAreas: function() {
            $('#area-announce').text = "Loading listing of all areas";
            this.area = {};
            this.step = '1';
        },
        loadAliasFunds: function(a) {  
            if (!a) {           
                this.area = this.areas.filter(a => {
                    return a.CAMPAIGN_ALIAS == this.$route.params.area.toLowerCase()+'/';
                })[0];                    
                if (this.area) {  
                    this.subArea = {'isSubArea': true, 'area': this.area};                                               
                } else {
                    this.$router.replace('/');
                }   
                a = this.area;
            }
            $('#area-announce').text = "Loading fund list for the "+a.TEMPLATE_TITLE;            
            this.aliasLoading = true;            
            this.featuredAllocations = [];                        
            this.step = '2';            
            this.area = a;  
            this.closeTooltip();
            
            this.featuredAllocations = a;
            this.aliasLoading = false;
        },
        closeTooltip: function() {            
            $('.tooltip').tooltip('hide');
        },
        testAmount: function() {
            this.cart.amount = this.cart.amount || 0;
        },
        setAmount: function(amt) {
            if (amt != 'other') {
                this.cart.amount = parseFloat(amt).toFixed(2);  
            } else {
                this.cart.amount = 0.00;
            }
            this.activeBtn = amt;
        },
        setEditing: function(editing) {
            this.editing = editing;
        },
        checkout: function() {
            var url = '';
            var amt = '';
            var sub = 0;
            var diff = 0;
            var dids = '';
            var cartAmount = 0;

            /*
            if (this.cart.funds.length) {
                alloc_school = this.cart.funds[0].ALLOCATION_SCHOOL;
            }
            var same_school = true; //Check if all allocations in the cart belong to the same allocation school
            */

            var numFunds = this.cart.funds.length; //How many funds have we donated to?
            if (this.cart.other) {
                numFunds = numFunds + 1;
            }

            cartAmount = this.cart.amount.toString().replace(/\,/g,'');
            cartAmount = parseFloat(cartAmount);

            sub = (cartAmount / numFunds).toFixed(2);
            diff = this.cart.amount - (sub * numFunds); //What is the difference between the divided-out found amounts and the cart amount?            

            url += 'https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7348&cid=11690';
            for (var i = 0; i < this.cart.funds.length; i++) {
                dids += this.cart.funds[i].ENCOMPASS_ID+'.'; //Build the DIDS list                            
                /*if (this.cart.funds[i].ALLOCATION_SCHOOL != alloc_school) {                    
                    same_school = false;        
                }*/
                amt += sub+'|';
            }

            if (this.cart.other) {
                dids += '9237.'; //Add the 'other' dids                  
                amt += sub+'|'; //Add an additional amt
            }

            amt = amt.replace(/\|+$/,''); //Strip off the last |            

            //Positive difference - ADD pennies to END amounts
            if (diff > 0) { //Add a penny to each amount for each penny the rounded amounts are off by (e.g. Math.round(500/6) = 83.33 * 6 = 499.98, 500 - 499.98 = 0.02, 0.01 added two times)
                //Split the amount string, reverse the resulting array so we don't have to worry about length
                var amtArr = amt.split("|").reverse();
                for (var i = 0; i < diff; i++) {
                    amtArr[i] = (parseFloat(amtArr[i]) + 0.01).toFixed(2); //Add a penny for each cent of difference in the summed rounded amounts
                }
                amt = amtArr.reverse().join("|"); //Reverse the array to its original direction, re-create as a string
            }
            //Negative diff - SUBTRACT pennies from BEGINNING amounts
            if (diff < 0) {
                //Split the amount string so we can iterate
                var amtArr = amt.split("|");
                for (var i = 0; i < Math.abs(diff); i++) { //Subtract a penny for each cent of difference
                    amtArr[i] = (parseFloat(amtArr[i]) - 0.01).toFixed(2);
                }
                amt = amtArr.join("|"); //re-create as a string

            }

            dids = dids.replace(/\.+$/,''); //Strip off the last .
            url += '&bledit=1';
            if (dids > '') { 
                url += '&dids=' + dids;
            }
            if (this.cart.otherDesc > '') {
                url += '&fund='+this.cart.otherDesc;
            }
            url += '&amt='+amt;
            if (this.$route.query.appealcode) {
                url += '&appealcode='+this.$route.query.appealcode;
            } else {
                if (this.$route.params.area) { //If the donor arrives on this page from a passed-in area URL, add that area's appeal code                                                        
                    url += '&appealcode='+this.subArea.area.APPEAL_CODE;                    
                }
            }
            var cookieObj = {cart: this.cart};
            this.$cookies.set('ncsu-og-portal', cookieObj);            
            window.location = url;                    
        }
    }
}
</script>