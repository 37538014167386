<template>
    <div>
        <h3 class="ml-3" v-if="loading">
            <i class="fas fa-circle-notch fa-spin"></i> Loading...
        </h3>                          
        <div v-else class="form-row">	
            <div class="col input-group form-label-group">
                <input type="text" v-on:input="showSearch()" id="allocationSearch" class="form-control" v-model="search" placeholder="Search by fund name">
                <label for="allocationSearch">Search by fund name</label>
                <div class="input-group-append">
                    <button type="button" :disabled="search == ''" v-on:click="search = ''; showSearch()" class="btn btn-secondary"><i class="fas fa-times"></i> Clear</button>
                </div>				
            </div>			
        </div>
        <div class="collapse" id="otherAllocations">
            <ul v-if="paginatedData.length" class="m-0 ml-sm-2 mr-sm-2 list-group list-group-flush">
                <li :key="i" class="list-group-item clearfix" v-for="(a, i) in paginatedData">                     
                    <div class="float-left fund-name pt-1 pl-2 pl-sm-0">
                        <span class="mr-2" v-html="$options.filters.highlight(a.ENCOMPASS_LONG_NAME, search.toLowerCase())"></span>
                        <i v-if="a.ALLOCATION_DESC > ''" class="fas fa-info-circle" v-tooltip:bottom="a.ALLOCATION_DESC"></i>
                        <span v-if="a.ALLOCATION_SCHOOL != 'GU'" class="badge badge-secondary">{{a.SCHOOL_NAME}}</span>
                        <!--<span class="badge badge-secondary" v-if="(a.ALLOCATION_SCHOOL == $parent.area.ALLOCATION_SCHOOL) && $parent.area.ALLOCATION_SCHOOL > ''">{{$parent.area.TEMPLATE_TITLE}}</span>-->
                    </div>
                    <div class="float-right">                        
                        <button type="button" v-if="!$parent.fundInCart(a)" v-on:click="addToCart(a)" class="btn btn-sm btn-red"><i class="fas fa-plus"></i> Add to cart</button>                                                
                        <button type="button" v-else v-on:click="$parent.removeFromCart(a)" class="btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove from cart</button>    
                    </div>
                </li>                                
            </ul>
            <div v-else class="alert alert-primary ml-2 mr-2"><i class="fas fa-info-square"></i> No funds match your search criteria</div>
            <div v-if="paginatedData.length" class="card-footer mt-2">
                <div class="row">
                    <div class="col-3">
                        <button class="btn btn-dark" type="button" :disabled="pageNumber == 0" v-on:click="prevPage()"><i class="fas fa-chevron-left"></i> Back</button>
                    </div>
                    <div class="col-6" style="text-align: center">
                        <span v-if="pageCount">
                            page {{pageNumber + 1}} of {{pageCount}}
                        </span>
                    </div>
                    <div class="col-3">
                        <button class="float-right btn btn-dark" type="button" :disabled="pageNumber == pageCount - 1 || !pageCount" v-on:click="nextPage()">Next <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <span id="search-announce" aria-live="assertive"></span>
    </div>
</template>
<script>
export default {
    props: ['allocations', 'loading'],
    data() {
        return {
            search: '',
		    size: 5,
            pageNumber: 0,            
            cart: this.$parent.cart
        }
    },
    methods: {
        showSearch: function() {
            if (this.search > '') {
                $('#otherAllocations').collapse('show');
                $('#search-announce').text = "Loading fund search results";
            } else {
                $('#otherAllocations').collapse('hide');
                 $('#search-announce').text = "Cleared fund search results";
            }
        },
        nextPage: function() {
            if (this.pageNumber < this.pageCount) {
                this.pageNumber++;
            }
        },
        prevPage: function() {
            if (this.pageNumber > 0) {
                this.pageNumber--;
            }
        },
        addToCart: function(a) {      

            this.$parent.addToCart(a);
            this.pageNumber = 0;            
        }        
    },
    computed: {
        filteredAllocations: function() {
            var alloc = JSON.parse(JSON.stringify(this.allocations));
            if (this.$parent.area) {
                if (this.$parent.area.ALLOCATION_SCHOOL > '') {
                    let a1 = this.allocations.filter(a => {               
                        return a.ALLOCATION_SCHOOL == this.$parent.area.ALLOCATION_SCHOOL;
                    });
                    let a2 = this.allocations.filter(a => {               
                        return a.ALLOCATION_SCHOOL != this.$parent.area.ALLOCATION_SCHOOL;
                    });
                    alloc = a1.concat(a2);
                }     
            }           
            var s = this.search.toLowerCase();
            return alloc.filter(a => {               
                return a.ENCOMPASS_LONG_NAME.toLowerCase().includes(s);
            });
        },
        pageCount: function() {
            let l = this.filteredAllocations.length;
            let s = this.size;
            var c = Math.floor(l/s);
            if (l % s > 0) {
                c++;
            }					
            return c;
        },
        paginatedData: function() {            
            if (this.pageNumber > this.pageCount) {                
                this.pageNumber = 0;
            }
            const start = this.pageNumber * this.size;            
            const end = start + this.size;
            return this.filteredAllocations.slice(start, end);
        }
    }
}
</script>
<style scoped>
.badge {
    border-radius: 0;
}
.modal-footer {
    display: block;
}
.list-group-item, .card {
    background-color: transparent;
}

.btn-dark {
    background-color: #333;
    border-color: #333;
}

.form-row>.col {
    padding-left: 15px;
    padding-right: 15px;
}

.form-control, .alert {
    border-radius: 0px;
}

.list-group, .form-label-group {
    padding: 0px;
}

.list-group-item {
    border-bottom: 1px solid #CCC;
}

.list-group-item:last-child {
    border-bottom: none;
}

.btn, .form-control, .input-group>.input-group-prepend>.input-group-text {
    border-radius: 0px;
}

.alert-primary {
    border-color: #b88800;
    background-color: #fac800;
    color: #000;
}

.btn-danger {
    background-color: #C00;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger:not(:disabled):hover {
    background-color: #900 !important;
}

.list-group-item > span {
    vertical-align: middle;
}


</style>