<template>
    <div id="env" class="text-center" v-if="env != 'PROD'">
        <div class="alert alert-dismissable alert-warning">
           <i class="fas fa-exclamation-triangle"></i> This is the <strong>{{env}}</strong> environment
		   <button type="button" class="ml-2 close" aria-label="Close">
                <i v-on:click="clearEnv()"  class="fas fa-times"></i>
            </button>
        </div>      
    </div>
</template>
<script>
export default {
	data: function() {
        return {
            env: process.env.VUE_APP_ENV
        }
    },
	methods: {
		clearEnv: function() {
			$('#env .alert').hide();
		}
	}
}
</script>
<style type="text/css">
#env {
	/* fixed position a zero-height full width container */
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 0;
	top: 0px;
	
	/* center all inline content */
	text-align: center;	
	z-index: 9998;			
}
		
#env > .alert {	
	border-top-right-radius: 0px;
	border-top-left-radius: 0px;
	display: inline-block;
	text-align: left;
}
</style>