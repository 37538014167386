<template>
    <div class="row" id="heading" :class="{'celebrating-transformation': isCT}">
        <div class="container">
            <div class="row">
                <div class="col-lg-12" id="giving-banner" :class="{'day-of-giving': isDoG, 'col-xl-6': isCT, 'col-xl-5': !isCT}">         
                    <div v-if="isDoG">
                        <img class="img-fluid" src="../assets/images/ncsu-dog-banner.png">
                    </div>
                    <div v-else-if="isCT">
                        <h1>Continue Our Momentum</h1>
                        <p>Together, we're honoring Chancellor Randy Woodson's tenure and strengthening NC State's future.</p>
                    </div>
                    <div v-else>
                        <h1>Giving to NC State</h1>
                        <p>With your investment, we will continue to think beyond boundaries and do the extraordinary.</p>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>
<script>
export default {         
    data() {
        return {
            isDoG: false,
            isCT: false        
        }
    },
    mounted() {        
        var area = this.$route.params.area;
        if (area == 'celebratingtransformation') {
            this.isCT = true;
        }
        var d = new Date();
        var appeal = this.$route.query.appealcode;             
        if (d >= this.$root.dogWindow.start && d < this.$root.dogWindow.end) {
            if (appeal !== false && (appeal == "DOG24BACKUP" || appeal == "DOG24WRDW24")) {
                this.isDoG = true;
            }
        }
    }
}
</script>
<style scoped>
@media (max-width: 482px) {
    #heading {
        height: inherit !important;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
    }
}

#heading { 
    height: 400px;
    background: url('../assets/images/bg.jpg') top center no-repeat;     
    padding-top: 225px;
    background-size: auto;
}

#heading.celebrating-transformation { 
    background: url('../assets/images/ct.jpg') top center no-repeat;     
}

#heading.celebrating-transformation h1 {
    font-size: 2.8rem;
}

#giving-banner {
    background-color: #C00;
    color: #FFF;    
    padding: 20px 20px 5px 20px;    
}

#giving-banner h1 {
    font-family: Roboto,Arial,sans-serif;
    font-weight: bold;
    font-size: 3rem;
    margin: 22px 0px 40px 0px;
}

#giving-banner p {
    font-family: 'Roboto';
    line-height: 1.6em;
    font-size: 1.125rem;   
}

#giving-banner.day-of-giving {
    padding: 25px;
    text-align: center;
}

#giving-banner.day-of-giving img {
    height: 175px;
}

</style>