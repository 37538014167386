<template>
<div class="container-fluid">
    <heading></heading>
    <div class="container">      
        <div class="mt-5 pt-5 mb-5 pb-5" v-if="redirecting">
            <h1><i class="fad fa-circle-notch fa-spin"></i> Redirecting you to our giving page...</h1>
        </div>
        <div style="margin-top: 70px" v-else>
            <div class="row mt-2 mb-3">
                <!-- Grow endowment header -->
                <div v-if="site == 'growendowment'" class="col">
                    <h1 class="pb-3 mb-2 border-bottom"><i class="fad fa-hand-holding-seedling"></i> Grow my endowment</h1>
                    <div class="pt-2"><p>Search for your endowment below and click "Add to cart". Please call 919.513.9238 if you have any questions or need assistance.</p></div>
                    <div v-if="hasId && !hasPledges && !loading" class="alert alert-warning">
                        <h4><i class="fad fa-fw fa-exclamation-triangle"></i> We couldn't find any endowments associated with this donor.</h4>
                        <span>If you'd like to give to another NC State fund, you can search our available funds below.</span>
                    </div>
                </div>
                <!-- Pay pledge header -->
                <div v-if="site == 'paypledge'" class="col">
                    <h1 class="pb-3 mb-2 border-bottom"><i class="fad fa-donate"></i> Pay a pledge</h1>
                    <div class="pt-2"><p>Search for a fund you've pledged towards below.</p><p>If you'd like to also donate to other areas within NC State, you can search our other available funds.</p></div>
                    <div v-if="hasId && !hasPledges && !loading" class="alert alert-warning">
                        <h4><i class="fad fa-fw fa-exclamation-triangle"></i> We couldn't find any pledges associated with this donor.</h4>
                        <span>If you'd like to give to another NC State fund, you can search our available funds below.</span>
                    </div>
                </div>
            </div>
            
            <div class="row mb-4 grey pt-3">
                <div class="col">
                    <div class="panel">
                        <h2>Search All Funds</h2>
                        <div class="row">
                            <div class="col pt-2 pb-2">
                                <allocation-select :loading="loading" :allocations="otherAllocations"></allocation-select>
                                <!-- <div class="p-2 border-top">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" v-model="cart.other" class="custom-control-input" id="otherFundCheck">
                                        <label class="custom-control-label pt-1" for="otherFundCheck">I would like to give to a fund not listed here</label>
                                    </div>
                                </div>
                                <div class="p-2" v-if="cart.other">
                                    <label for="otherDesc" aria-hidden="false" style="display: none">How should your gift be used?</label>
                                    <input v-model="cart.otherDesc" id="otherDesc" type="text" placeholder="How should your gift be used?" class="form-control"> 
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div class="col">
                    <div class="p-1 mb-2 border-bottom">
                        <div class="row align-items-center text-right">
                            <div class="col">
                                <img class="align-baseline" src="@/assets/images/gift.png" alt="My cart">
                                <!-- {{cart.funds.length + cart.other}} fund<span v-if="cart.funds.length + cart.other != 1">s</span> selected -->
                                {{cart.funds.length}} fund<span v-if="cart.funds.length != 1">s</span> selected
                                <button type="button" v-if="cartEditEnabled" v-on:click="setEditing(!editing)" data-toggle="collapse" data-target=".cartDetail" class="btn btn-sm btn-red ml-2">
                                    <span v-if="editing"><i class="fas fa-check"></i> Done</span>
                                    <span v-else><i class="fas fa-pencil"></i> Edit cart</span>
                                </button>      
                            </div>
                        </div>                
                        <div class="cartDetail collapse">                        
                            <ul class="list-group list-group-flush text-right">
                                <li :key="i" class="list-group-item" v-for="(a, i) in cart.funds">
                                    <span class="mr-2">{{a.ENCOMPASS_NAME}}</span>
                                    <button type="button" v-on:click="removeFromCart(a)" class="float-right btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove</button>    
                                </li>
                                <!-- <li class="list-group-item" v-if="cart.other">
                                    <span class="mr-2">Other fund<span v-if="cart.other && cart.otherDesc">: {{cart.otherDesc}}</span></span>
                                    <button type="button" v-on:click="removeOther()" class="float-right btn btn-sm btn-dark"><i class="fas fa-ban"></i> Remove</button>    
                                </li> -->
                            </ul>  
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="row mb-5">
                <div class="col text-center">
                    <button type="button" :disabled="!checkoutEnabled" v-on:click="checkout()" class="btn btn-continue">
                        <span>Continue</span> <i class="fas fa-chevron-right"></i>
                    </button>     
                </div>
            </div>                   
        </div>    
    </div>
    <foot></foot>
</div>
</template>
<script>
const cart = {other: false, otherDesc: '', amount: 0, giftType: 'now', funds: []};
import allocationSelect from '../allocation.select.vue';
import heading from '../head.vue';
import foot from '../foot.vue';
export default {
    components: {
        allocationSelect: allocationSelect,
        heading: heading,
        foot: foot
    },
    data() {
        return {
            loading: false,
            redirecting: false,
            editing: false,
            entityId: '',            
            hasId: false,
            hasPledges: false,          
            otherAllocations: [],            
            params: {},  
            cart: cart,
            site: false,
            siteData: {},
            defaults: {       
                'paypledge': {
                    cookie: 'ncsu-og-paypledge',                    
                    params: {APPEAL_CODE: 'WPLGPAY'},                    
                    dataSrc: './data/php/payment/donor.pledge.lookup.php'
                },
                'growendowment': {
                    cookie: 'ncsu-og-growendowment',
                    params: {APPEAL_CODE: 'ESRE'},
                    dataSrc: './data/php/payment/steward.allocations.lookup.php',
                }
            }
        }
    },
    methods: {
        getData: function() {
            this.site = this.$route.name;            
            this.siteData = this.defaults[this.site];                     
            if (this.$cookies.isKey(this.siteData.cookie)) {
                let c = this.$cookies.get(this.siteData.cookie);
                this.cart = c.cart;                                                               
            }
            this.loading = true;                        
            var promises = [];                             
            
            promises.push(this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/json/all-allocations.json'));
            //If an entity ID has been passed in via the URL
            var id = '';
            if (this.$route.params.id) { 
                id = this.$route.params.id;                  
            }            
            if (Object.keys(this.$route.query).length) {
                id = Object.keys(this.$route.query)[0];                 
            }
            if (id) {    
                let pattern = new RegExp('^[0-9]{1,10}$');
                if (pattern.test(id)) {
                    this.hasId = true;
                    promises.push(this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+this.siteData.dataSrc, {params: {'id': id}}));
                }
            }

            Promise.all(promises).then(response => {	                
                if (response[0].data.error) { //All allocations
                    this.$parent.setAlert(true, response[0].data.error, 'alert-danger');
                    this.loading = false;
                    return;
                } else {
                    this.otherAllocations = response[0].data;
                    for (var i = 0; i < this.otherAllocations.length; i++) {                                               
                        this.$set(this.otherAllocations[i], 'inCart', false);
                        this.loading = false;
                    }
                    if (this.hasId) { //Another promise should exist
                        if (response[1].data.error) {
                            this.$parent.setAlert(true, response[1].data.error, 'alert-danger');
                            this.loading = false;
                            return;
                        } else {                                                        
                            if (response[1].data.length > 0) { //One or more allocations exist, redirect
                                this.cart.funds = response[1].data;
                                for (var i = 0; i < this.cart.funds.length; i++) {                                               
                                    this.$set(this.otherAllocations.find(c => {
                                        return c.ENCOMPASS_ID == this.cart.funds[i].ENCOMPASS_ID;
                                    }), 'inCart', true);
                                }
                                this.hasPledges = true;                                
                                this.checkout();
                                this.loading = false;                                                              
                                return;
                            } else {
                                this.loading = false;
                                this.hasPledges = false;
                                this.cart = cart;
                            }                            
                        }
                    }
                }                                              
            });
        },
        addToCart: function(a) {
            a.inCart = true;
            this.cart.funds.unshift(a);  
            var cookieObj = {cart: this.cart};
            this.$cookies.set(this.siteData.cookie, cookieObj);          
        },
        fundInCart: function(a) {
            var out = false;
            if (this.cart.funds.find(f => f.ENCOMPASS_ID == a.ENCOMPASS_ID)) {
                out = true;
            }
            return out;
        },
        removeFromCart: function(a) {
            a.inCart = false;            
            this.cart.funds = this.cart.funds.filter(c => {
                return a.ENCOMPASS_ID != c.ENCOMPASS_ID;
            })   
            this.otherAllocations.filter(o => {
                return o.ENCOMPASS_ID == a.ENCOMPASS_ID;
            })[0].inCart = false;
            var cookieObj = {cart: this.cart};
            this.$cookies.set(this.siteData.cookie, cookieObj);          
            //If this empties the cart, collapse it
            if (this.cart.funds.length + this.cart.other === 0) {
                $('.cartDetail').collapse('hide');
                this.setEditing(false);                
            }
        },
        setEditing: function(editing) {
            this.editing = editing;
        },
        checkout: function() {            
            this.redirecting = true;
            window.location.href = this.imodUrl();
        },
        imodUrl: function() {        
            var url = 'https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7348&cid=11690';
            var dids = '';

            if (this.siteData.params.APPEAL_CODE) {
                url += '&appealcode='+this.siteData.params.APPEAL_CODE;
            }
            
			if (this.cart.funds.length) {
                url += '&bledit=1&sort=1&dids=';	
                for (var i = 0; i < this.cart.funds.length; i++) {
                    let v = this.cart.funds[i];
					if (v.ENCOMPASS_ID > '') {
						dids += v.ENCOMPASS_ID+'.'; //Build the DIDS list						
					}
				}
				dids = dids.replace(/\.+$/,''); //Strip off the last .
				url += dids;
			}
			return url;
        }
    },
    computed: {
        checkoutEnabled: function() {
            var out = false;          
            //if (this.cart.funds.length > 0 || this.cart.other === true) {
            if (this.cart.funds.length > 0) {
                out = true;
            }
            return out;
        },
        cartEditEnabled: function() {
            var out = false;
            //if ((this.cart.funds.length > 0 || this.cart.other === true)) {
            if (this.cart.funds.length > 0) {
                out = true;
            }
            return out;
        }
    },
    mounted: function() {
        this.getData();
    }
}
</script>
<style>
@media (max-width: 482px) {
    .container-cart {
        margin-top: 0px !important;

    }

    .btn-back {
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
    }

    h3.custom-control {
        font-size: 1.05rem !important;
        font-weight: bold;
    }

    .custom-control-lg .custom-control-label::before, .custom-control-lg .custom-control-label::after {
        width: 1.2rem !important;
        height: 1.2rem !important;
    }

    
}
@media (max-width: 768px) {
    h3 {
        font-size: 1.4rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    span.h3 {
        font-size: 2.0rem !important;
    }
}

@media (min-width: 769px) and (max-width: 992px) {
 
}

/*Safari fix */
.row:before, .row:after {
    width:0px; height:0px;
}

body {
    background-color: transparent;
}

.area {
    margin: 0;
    background-color: #C00;
}

.area div {
    padding: 0;    
}

.area .show-all {
    
}

.custom-control-label {
	padding-left: .25rem;
    padding-top: 2px !important;
}
.custom-control-label::before, 
.custom-control-label::after {    
    width: 1.25rem;
    height: 1.25rem;	
    margin-top: 0rem;
    border-radius: 0;
}

.btn, .form-control, .input-group-lg .form-control, .input-group>.input-group-prepend>.input-group-text {
    border-radius: 0px;
}

.category-title i {
  margin-top: 3px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-out;
}

.category-title i.collapsed {
  transform: rotate(-90deg);
  transition: transform 0.3s ease-in;
}

h2 {
    padding: 10px;    
    /*background-color: #C00;*/
    color: #C00;
    font-family: Roboto,Arial,sans-serif;    
    font-weight: bold;
    font-size: 1.5rem;
    text-transform: uppercase;
}

h3 {
    font-size: 1.5rem;
}

h3.custom-control {
    font-size: 1.2rem;
}

h3, h4 {
    font-family: Roboto,Arial,sans-serif;
    color: #333;    
}

h3[data-toggle] {
    cursor: pointer;
}

.list-group-item {
    background-color: transparent;    
    padding: 0.5rem;
    border: none;
}

.list-group-item .list-group-item {
    padding: 0.5rem;    
    border-bottom: 1px solid #CCC;
}

.panel {    
    background-color: #FFF;   
    margin-bottom: 10px;
    /*border: 2px solid #C00; */
}

.panel h2 {
    margin: 0px;
    padding: 0px;
}

.panel > .row{
    padding: 0px;
}

.cart {
    background-color: #FFF;
}

.row.grey, .row.grey .panel {
    background-color: #EEE;
}


.cartDetail {
    padding-top: 5px;
}

.cartDetail .list-group-item {
    padding: 5px 0px;
    border-color: #dee2e6;
}

.btn-continue {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1rem;
    padding: 10px 36px;
}

.btn-continue i {
    padding: 0px 0px 0px 10px;
}

.btn.btn-back .glyphicon-bold-arrow {    
    left: 0px !important;
}

.btn.btn-back:hover .glyphicon-bold-arrow {    
    left: -4px !important;
}

.btn-back:hover .glyphicon-bold-arrow {    
    right: 4px;
}

.btn-back .glyphicon.glyphicon-bold-arrow {
    transform: rotate(180deg);
}

.btn-red, .btn-amt, .btn-continue {
    background-color: #C00;
    color: #FFF;    
    border: 1px solid #C00;
}

.btn-continue:focus, .btn-continue:active {
    color: #FFF;
}

.btn-red:hover {
    background-color: #900;
    border-color: #900; 
    color: #FFF;   
}

.btn-area, .btn-amt, .btn-greatest {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;    
    font-family: 'Roboto';    
}

/*.btn-area {
    background-color: #FFF;
    color: #333;    
    border: 1px solid #333;
    font-size: 1rem;
}*/

.btn-area, .btn-area:active, .btn-area:focus {
    background-color: #333;
    color: #FFF;
    border: 1px solid #000;
    font-size: 1rem;
}


.btn-area:hover {
    background-color: #FFF;
    color: #333;    
    border: 1px solid #333;
}

.btn-amt {    
    font-size: 2.1rem;
}

.btn-amt:hover, .btn-amt.active {
    background-color: #900;
    border-color: #900;
    color: #FFF;
}

.btn-amt:not(:disabled).active:focus {
    box-shadow: none;
}


.btn-amt:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    border-style: solid;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #900;
    width: 0;
    height:0;
    z-index: 1;
    opacity: 0;
    transition: 0.2s;
}

.btn-amt:hover:after, .btn-amt.active:after {
    opacity: 1;
}

.btn-amt.active:after {    
    border-top: 8px solid #900;
}

.btn-amt.active:hover:after {    
    border-top: 8px solid #900;
}


.btn-dark:hover, .btn-continue:not(:disabled):hover {
    background-color: #333;
    border-color: #333;    
}

.btn-dark:active, .btn-dark:focus {
    color: #FFF;
}

.btn-continue:hover {
    color: #FFF;
}

.row-other {
    margin: 0px;
}

.row-other > div {
    margin: 20px 0px;
}

.row-other .h3 {
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 2.5rem;
}

.row-other .input-group .form-control {
    border: 2px solid #C00;    
}

.row-other .form-control, .row-other .input-group-prepend {
    border: none;
}

.row-other .input-group-prepend .input-group-text {
    border: none;
    color: #FFF;
    font-weight: bold;
    font-size: 1.6rem;
    padding: 0px 20px;
    font-family: 'Roboto';
    background-color: #C00;
}

.row-other .input-group-lg>.form-control:not(textarea) { 
    height: 55px;
}

.btn-greatest {

}

.areas {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}

.btn-amt-custom {
    padding-top: 2px;
}

.btn-amt-custom div {
    display: inline-block;
    vertical-align: -4px;
}

.btn-amt-custom input.form-control {    
    width: 75%;
    display: inline;
    font-size: 1.2rem;
    font-family: 'Roboto';
}

.btn-amt sup {
    top: -10px;
    font-size: 0.6em;
    font-weight: bold;
}

.btn-block {
    background-color: #333;
    border-color: #000;
}

.btn-block:hover, .btn-block:active, .btn-block:focus {
    background-color: #FFF;
    border-color: #000;
    color: #000;
}

.btn-block:hover label, .btn-block:active label, .btn-block:focus label {
    color: #000 !important;
}

.btn:focus,.btn:active, .form-control:focus, .form-control:active {
   outline: none !important;
   box-shadow: none;
   border-color: #ced4da !important;
}

.inactive .long-name {
    color: #666;
}

.list-group-item.inactive {
    background-color: #EEE;
}

.list-group-item .detail {
    font-size: 0.8rem;
}

.card, .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-top: none;
    
}

.category-title {
    background-color: #333;
    color: #FFF;
    padding: 3px;
    font-size: 1.2rem;
}

.search .form-control, .search .input-group-append > .btn {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.default-enter-active, .default-leave-active,
.featuredAllocations-enter-active, .featuredAllocations-leave-active,
.otherAllocations-enter-active, .otherAllocations-leave-active {
  transition: all .3s ease;
}

.default-enter, .default-leave-to {
    opacity: 0;  
    transform: translateY(100px);
}

.featuredAllocations-enter, .featuredAllocations-leave-to,
.otherAllocations-enter, .otherAllocations-leave-to {
  opacity: 0;  
}


.default-leave, .default-enter-to {
    opacity: 1;
    transform: translateY(0);
}

.featuredAllocations-leave, .featuredAllocations-enter-to,
.otherAllocations-leave, .otherAllocations-enter-to {
    opacity: 1;    
}

.custom-control-lg .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #900;
    background-color: #C00;
}

.custom-control-input:active~.custom-control-label::before {
    background-color: #FFF !important;
    border: #adb5bd solid 1px !important;
}

.custom-control-lg .custom-control-input:focus~.custom-control-label::before, .custom-control-input:focus~.custom-control-label::before {
    border: #adb5bd solid 1px !important;
    box-shadow: none;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #C00;
    background-color: #C00;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0;
}


.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: transparent !important;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
    color: transparent !important;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
    color: transparent !important;
}
.form-control:-moz-placeholder { /* Firefox 18- */
    color: transparent !important;
}

.custom-control-lg .custom-control-label {
    padding-left: 0.5rem;
}

.custom-control-lg .custom-control-label::before, .custom-control-lg .custom-control-label::after {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -2px;    
}
.search-highlight {
    background-color: #333;
    color: #FFF;
}

</style>