<template>
    <div class="modal fade" id="modalPledgeDelete" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">                    
                    <h3><i class="far fa-check-circle text-primary"></i> Confirm your payroll deduction cancellation</h3>            
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal-body">
                    <h4>Are you sure you want to cancel the following pledge?</h4>
                    <div class="row ml-2">
                        <div class="col-12">{{pledge.ALLOCATION_NAME}}</div>                                                    
                        <div class="col-12">
                            <span v-if="pledge.PLEDGE_PLEDGE_TYPE == 'P'">Open-ended pledge, monthly payments of {{Number(pledge.PRIM_PLEDGE_PAYMENT_AMOUNT) | toCurrency}}</span>
                            <span v-if="pledge.PLEDGE_PLEDGE_TYPE == 'PD'">Recurring pledge, <strong>{{pledge.NUMBER_OF_PAYMENTS}}</strong> monthly payments of <strong>{{Number(pledge.PRIM_PLEDGE_PAYMENT_AMOUNT) | toCurrency}}, {{Number(pledge.PRIM_PLEDGE_AMOUNT) | toCurrency}}</strong> in total</span>            
                        </div>
                    </div>
                </div>
                <div class="modal-footer">    
                    <div class="float-right">                
                        <button type="button" class="btn btn-success mr-2" v-on:click="cancelPledge()"><i class="fas fa-check"></i> Yes, cancel this pledge</button>                
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"><i class="fas fa-times"></i> No, do not cancel</button>                
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
    props: ['pledge'],
    data() {
        return {
            search: '',
		    size: 5,
            pageNumber: 0
        }
    },
    methods: {
        cancelPledge: function() {
            var _this = this;                    
            var p = Object.assign({}, this.pledge);
            p.action = 'delete';
            var data = {                
                user: this.$parent.user,
                pledge: p,
                cutoff: this.$parent.cutoff				
            };	
			var formData = new FormData();
            formData.append("data", JSON.stringify(data));                
            this.axios({
                method: 'post',
                url: this.$config[process.env.VUE_APP_ENV].app.base+'data/php/payroll/pledge.update.php',                
                data: formData
            }).then(response => {                
                //Remove deleted pledge                               
				if (response.data.error) {
					_this.$root.setAlert(true, response.data.error, 'alert-danger');
					return;
				} else {
                    if (p.NCSU_PD_PLEDGE_SEQ > '') {
                        _this.$parent.pledges = _this.$parent.pledges.filter(pl => {return pl.NCSU_PD_PLEDGE_SEQ != p.NCSU_PD_PLEDGE_SEQ});
                    } else {
                        _this.$parent.pledges = _this.$parent.pledges.filter(pl => {return pl.PLEDGE_PLEDGE_NUMBER != p.PLEDGE_PLEDGE_NUMBER});
                    }
                    $('#modalPledgeDelete').modal('hide');         
                }                
    		}).catch(error => {
				_this.$root.setAlert(true, error, 'alert-danger');
				return;
    		}); 
        },  
    }
}    
</script>
<style>
.modal-footer {
    display: block;
}
</style>