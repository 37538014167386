<template>
    <div class="container">
        <div class="row" id="heading">
            <div class="col text-center">
                <h1 class="display-5 border-bottom pb-2 d-none d-sm-block"><i class="text-danger fad fa-ban"></i> This website is currently unavailable</h1>
            </div>
        </div>
        <div class="row">
            <div class="col text-center">
                <h4>
                    We apologize for the inconvenience.
                </h4>
                <h4>
                    Please contact Advancement Services at <a href="mailto:giftsandrecords@ncsu.edu">giftsandrecords@ncsu.edu</a> with any questions or concerns.
                </h4>
            </div>
        </div>        
    </div>
</template>
<script>    
    export default {                   
        mounted() {
            fetch(process.env.BASE_URL + "php/data/site-status.json")
	        .then((response) => response.json()).then((status) => {		
                console.log(status);
		        if (status[0].SITE_STATUS != 'down') { 
               //     this.$router.push({path: '/', replace: true});
		        }
	        });
        }
    }
</script>
<style scoped>
    /*Safari fix */
    .row:before, .row:after {
        width:0px; height:0px;
    }
    .container {
        padding: 80px 0px 60px;
    }
    
    h2 {
        font-family: 'Roboto';    
    }
    
    h3 {
        font-size: 1.5rem;
        font-family: Roboto Slab,serif;       
    }
    
    .display-5 {
        font-size: 3.0rem;
        font-weight: 300;
        line-height: 1.2;
    }
</style>