<template>
    <div class="text-center mt-3 pt-3">
        <h1><i class="fad fa-circle-notch fa-spin"></i> Please wait while the page loads...</h1>
    </div>
</template>
<script>
export default {
    mounted() {
        switch (this.$route.name) {
            case 'purl': 
                this.redirectPurl();
            break;
            case 'phone': 
                this.redirectPhone();
            break;
            default: {
                this.$router.push({name: 'default'});
            }
        }        
    },
    methods: {
        redirectPhone: function() {
            var p = this.$route.params.id;            
            if (!p) {
                window.location.replace("https://give.ncsu.edu/");	// No ID
            } else {
                this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/phone/pledge.data.php', {
                    params: {
                        id: parseInt(p, 10).toString().padStart(10, '0')
                    }
                }).then(response => {		                				
                    if (!response.data.error) {
                        if (response.data[0][0].ENTITY_COUNT > 0) { //Valid entity
                            if (response.data[1].length == 0) {
                                window.location.replace("https://give.ncsu.edu/?appealcode=AGRNLGP21");	//No pledges
                            } else {
                                var url = "https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7348&cid=11690&bledit=1&sort=1&appealcode=AGRNLPP21";
                                var dids = [];
                                var amt = [];
                                for (var i = 0; i < response.data[1].length; i++) {
                                    dids.push(response.data[1][i].ENCOMPASS_ID);
                                    amt.push(response.data[1][i].PRIM_PLEDGE_AMOUNT).toFixed(2);
                                }
                                url+='&dids='+dids.join('.')+"&amt="+amt.join('|');
                                window.location.replace(url);
                            }															
                        } else {
                            window.location.replace("https://give.ncsu.edu/");	// Invalid ID
                        }
                    }
                }).catch(error => {
                    console.log(error);
                    return;
                });
            }            				
        },
        redirectPurl: function() {            
            var url = 'https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7315&cid=11633&bledit=1&amount=0&sort=1';	
            var p = this.$route.params.purlid;  
            var a = this.$route.query.appealcode;
            var appealcode = 'AGPURLG';
            if (a) {          
              appealcode = a;    
            }
            if (!p) {
                window.location.replace("https://give.ncsu.edu/?appealcode="+appealcode);
            } else {
                var out = '';
                var params = {purlid: p.toUpperCase()};              
                this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/purl/purl.lookup.php', {params: params})
                .then(response => {	

                    if (!response.data.length) {
                        out = "https://give.ncsu.edu/?appealcode="+appealcode;
                    } else {  									
                        if (response.data[0].ID_NUMBER) {
                            if (response.data[0].ID_NUMBER > '') {
                                out = url + '&appealcode='+appealcode+'&dids='+response.data[0].DIDS+'&btnvals='+response.data[0].BTNVALS;                                    
                            } else {
                                out = url+'&appealcode='+appealcode;
                            }
                        } else {
                            out = "https://give.ncsu.edu/?appealcode="+appealcode;
                        }   
                    }
                    window.location.replace(out);
                }); 
            }	            								
        }
    }
}
</script>