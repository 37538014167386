//Baee components
import Vue from 'vue';
import app from './app.vue';

//External modules
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';

//Internal modules
import home from './components/main.vue';
import environment from './components/environment.vue';
import alert from './components/alert.vue';

import payroll from './components/payroll/default.vue';
import payment from './components/payment/default.vue';
import redirect from './components/give/redirect.vue';
import error from './components/error.vue';


//CSS
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@/assets/js/fontawesome.js';
import '@/assets/css/webfonts.css';
import '@/assets/css/floating-labels.css';
import '@/assets/css/give.css';

//jQuery
window.$ = window.jQuery = require('jquery');

Vue.config.productionTip = false;

Vue.component('alert', alert);
Vue.component('environment', environment);

Vue.use(VueCookies);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);

const alertDefault = {
  display: false,
  message: '',
  class: 'alert-primary',
  timeout: false
};

//Day of giving
var d = new Date();
var o = 4 - (d.getTimezoneOffset() / 60); //Eastern time offset
var dogStart = new Date('2024', '2', '19', 23 + o, 45, 0); //Date / time + 4 hours for Eastern time UTC offset, use local date to adjust UTC offset
var dogEnd = new Date('2024', '2', '20', 23 + o, 59, 59);	

//var rwStart = new Date('2024', '9', '28', 7 + o, 59, 59);
var rwEnd = new Date('2024', '10', '1', 16 + o, 59, 59);

//Routes and route components
const router = new VueRouter({
	mode: 'history',		
	routes: [
        { 
			beforeEnter(to, from, next) { //Remove trailing slash
				var p = to.path;    
				if (to.path != '/' && to.path[to.path.length - 1] === '/') {						
					p = to.path.slice(1, to.path.length - 1).split("/")[1];				
					next({name: 'growendowment', params: {id: p}});		
				} else {
					next();
				}				
        	},
			name: 'growendowment',
			path: '/growendowment/:id?', 
			components: {
				default: payment
			}	
        },		
        { 
			beforeEnter(to, from, next) { //Remove trailing slash
				var p = to.path;    
				if (to.path != '/' && to.path[to.path.length - 1] === '/') {						
					p = to.path.slice(1, to.path.length - 1).split("/")[1];				
					next({name: 'paypledge', params: {id: p}});	
				} else {
					next();
				}			
			},		
			name: 'paypledge',
			path: '/paypledge/:id?', 
			components: {
				default: payment
			}	
		},		
      	{ 
			name: 'payroll',
			path: '/payroll', 
			components: {
				default: payroll
			},
			meta: {
				title: 'NC State University Payroll Deduction Portal'        		
			}	
  		},
      	{
        	name: 'purl',
        	path: '/u/:purlid?',
        	components: {
				default: redirect
			},
      	},
		{
        	name: 'phone',
        	path: '/phone/:id?', 
        	components: {
          		default: redirect
        	}
		},	
		{
			name: 'error',
			path: '/error',
			components: {
				default: error
			}
		},        		
		{ 
        	name: 'default',
        	path: '/:area?', 			
        	beforeEnter(to, from, next) { //Remove trailing slash
				var p = to.path;    
				//Force DoG date to be out of window when override appeal is set
				var appeal = Object.keys(to.query).indexOf('appealcode');
				if (appeal !== false && (to.query['appealcode'] == "DOG24BACKUP" || to.query['appealcode'] == "DOG24WRDW24" || to.query['appealcode'] == "DOG24PD")) {
					d = new Date('1900', '1', '1', 23 + o, 59, 59);	
				}								
          		if (d >= dogStart && d < dogEnd) {
              		var keys = Object.keys(to.query);
              		var url = 'https://securelb.imodules.com/s/1209/give19/form.aspx?sid=1209&gid=214&pgid=7348&cid=11690';
              		if (keys.length) {
                  		for (var i = 0; i < keys.length; i++) {
                      		url += '&'+keys[i]+'='+to.query[keys[i]];
                  		}
              		}
              		window.location = url;
          		} else if (p == '/redandwhite' && (d >= rwEnd)) {
					//Redirect users to student giving challenge page if outside of the red and white week challenge window
					var url = 'https://redwhiteweek.ncsu.edu/student-giving-challenge/';
					window.location = url;
				} else {                     		            					
              		if (to.path != "/" && to.path[to.path.length - 1] === '/') {			                	
                		p = to.path.slice(1, to.path.length - 1);						
                		next({name: 'default', params: {area: p}});			
              		} else {  
                		next();
              		}				
          		}
        	},
			components: {
				default: home
			},
			meta: {
				title: 'Give to NC State',
				metaTags: [
					{name: "description",  content: "At NC State, we power the extraordinary - with your support."},
					{property: "og:title",  content: "Give to NC State"},
					{property: "og:description",  content: "At NC State, we power the extraordinary - with your support."},
					{property: "og:url",  content: "https://give.ncsu.edu/"},
					{property: "og:image",  content: "https://thinkanddo.ncsu.edu/discover/annual-giving/wp-content/uploads/sites/4/2022/03/MAH0061_v1.jpg"},
					{property: "og:type",  content: "website"},
					{name: "twitter:card",  content: "summary_large_image"},
					{name: "twitter:site",  content: "@ncstate"},
					{name: "twitter:creator",  content: "@ncstate"},
					{name: "twitter:title",  content: "Give to NC State"},
					{name: "twitter:description",  content: "At NC State, we power the extraordinary - with your support."},
					{name: "twitter:image",  content: "https://thinkanddo.ncsu.edu/discover/annual-giving/wp-content/uploads/sites/4/2022/03/MAH0061_v1.jpg"}
				]
			}	
		},	
		{
			path: '*',
			redirect: "/"
		}
	]	
});

router.beforeEach((to, from, next) => {	

	fetch(process.env.BASE_URL + "data/json/site-status.json")
	.then((response) => response.json()).then((status) => {		
		if (status[0].SITE_STATUS == 'down' && to.name != 'default' && from.name != 'error') { 			
			next({name: 'error'});
		}		
	});


	// This goes through the matched routes from last to first, finding the closest route with a title.
	// eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
	const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
	const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
	// If a route with a title was found, set the document (page) title to that value.
	if(nearestWithTitle) document.title = nearestWithTitle.meta.title;
  
	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
	// Skip rendering meta tags if there are none.
	if(!nearestWithMeta) return next();
  
	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags.map(tagDef => {
		const tag = document.createElement('meta');
  
	  	Object.keys(tagDef).forEach(key => {
			tag.setAttribute(key, tagDef[key]);
	  	});
  
	  	// We use this to track which meta tags we create, so we don't interfere with other ones.
	  	tag.setAttribute('data-vue-router-controlled', '');
  
	  	return tag;
	})
	// Add the meta tags to the document head.
	.forEach(tag => document.head.appendChild(tag));
  
	next();
});

fetch(process.env.BASE_URL + "config.json")
.then((response) => response.json()).then((config) => {
	Vue.prototype.$config = config;    
	new Vue({
		router,
		data: {
			user: {},
			alert:  JSON.parse(JSON.stringify(alertDefault)),   
			dogWindow: {'start': dogStart, 'end': dogEnd},			
			loggedIn: false,
			config: {}
		},      
		methods: {   
			clearAlert: function () {
				this.alert = JSON.parse(JSON.stringify(alertDefault));
			},
			setAlert: function (display, message, css) {
				this.alert.display = display;
				this.alert.class = css;
				this.alert.message = message;
			},
		},
		mounted() {
		},
		render: h => h(app)
	}).$mount('#app');
});
Vue.filter('highlight', function(item, query) {		
	if (item == null) {
		return;
	}
	var invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_]+/g;
	var repl = query.trim().replace(invalid, "");	
	var check = new RegExp(repl, "ig");	
	return item.toString().replace(check, function(matchedText,a,b) {
		return ('<span class="search-highlight">' + matchedText + '</span>');
	});
});
Vue.filter('toCurrency', function (value) {
	if (typeof value !== "number") {
		return value;
	}
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD'
	});
	return formatter.format(value);
});
Vue.directive('tooltip', function(el, binding) {
	$(el).tooltip({
		title: binding.value,
		placement: binding.arg,
		trigger: 'hover',
		delay: {"show": 100},
		offset: 'top,5px',
		template: '<div class="tooltip" role="tooltip"><div class="tooltip-close"><i v-on:click="closeTooltip()" class="fas text-white fa-lg fa-times-circle"></i></div><div class="tooltip-inner"></div></div>'
	});
});
Vue.directive('tooltipred', function(el, binding) {
	$(el).tooltip({
		title: binding.value,
		placement: binding.arg,
		trigger: 'hover',
		delay: {"show": 100},
		offset: 'top,15px',
		template: '<div class="tooltip tooltip-red" role="tooltip"><div class="tooltip-close"><i v-on:click="closeTooltip()" class="fas text-white fa-lg fa-times-circle"></i></div><div class="tooltip-inner"></div></div>'
	});
});